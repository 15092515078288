import Layout from '../components/common/Layout';
import HeroBanner from '../components/home/HeroBanner';
import ServiceCards from '../components/home/ServiceCards';
import AboutSection from '../components/home/AboutSection';
import CoursesSection from '../components/home/CoursesSection';
import TestimonialsSection from '../components/home/TestimonialsSection';
import CirtificateSection from '../components/home/CirtificateSection';
import '../assets/css/home.css';

const HomePage = () => {
    return (
        <Layout>
            <div
                data-elementor-type="wp-page"
                data-elementor-id={1204}
                className="elementor elementor-1204"
            >
                <h1 style={{display:"none"}}>Creative Insight</h1>
                <HeroBanner />
                <ServiceCards />
                <AboutSection />
                <CoursesSection />
                <TestimonialsSection />
                {/*<TeamSection />*/}
                <CirtificateSection />
            </div>
        </Layout>
    );
};

export default HomePage;
