import Layout from '../components/common/Layout';
import Breadcrumb from '../components/common/Breadcrumb';
import ContactForm from '../components/contact/ContactForm';
import ContactDetails from '../components/contact/ContactDetails';
import GoogleMapsSection from '../components/contact/GoogleMapsSection';
import "../assets/css/contact.css"

const ContactPage = () => {
    return(
        <Layout>
            <Breadcrumb title='Contact' startPath='Creative Insight' endPath='contact' />
            <h1 style={{display:"none"}}>Creative Insight</h1>
                <div
                    data-elementor-type="wp-page"
                    data-elementor-id={52}
                    className="elementor elementor-52"
                >
                    <ContactForm />
                    <ContactDetails />
                   <GoogleMapsSection />
                </div>
        </Layout>

    )
}
export default ContactPage