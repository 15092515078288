import { Link } from 'react-router-dom';

const Breadcrumb = ({title,startPath,endPath}) => {
    return (
        <section
            className="page-header page-header--bg-two"
            data-jarallax=""
            data-speed="0.3"
            data-imgposition="50% -100%"
        >
            <div className="page-header__bg jarallax-img" />
            <div className="page-header__overlay" />
            <div className="container text-center">
                <h2 className="page-header__title" style={{ color: 'white' }}>
                    {title}
                </h2>
                <ul className="page-header__breadcrumb list-unstyled" style={{ color: 'white' }}>
                    <li className="home">
                        <span property="itemListElement" typeof="ListItem">
                            <Link title="Go to Home Page." to="/">
                                <span
                                    property="name"
                                    style={{ color: 'white' }}
                                >
                                    {startPath}
                                </span>
                            </Link>
                            <meta property="position" content={1} />
                        </span>
                    </li>
                    <li className="post post-page current-item" >
                        <span property="itemListElement" typeof="ListItem">
                            <span
                                property="name"
                                className="post post-page current-item"
                                style={{ color: 'white' }}
                            >
                                {endPath}
                            </span>
                            <meta property="url" content="index.html" />
                            <meta property="position" content={2} />
                        </span>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Breadcrumb;
