import SvgIcon from '../common/Icon/SvgIcon';
import { statisticData } from '../../utils/data';

const StatisticsSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-15a2f855 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="15a2f855"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-23541935"
                    data-id={23541935}
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-797ae2f6 elementor-widget elementor-widget-eduact-counter"
                            data-id="797ae2f6"
                            data-element_type="widget"
                            data-widget_type="eduact-counter.default"
                        >
                            <div className="elementor-widget-container">
                                <section
                                    className="fact-one"
                                    style={{
                                        backgroundImage:
                                            'url(../wp-content/uploads/2023/09/fact-bg-1.png)',
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            {statisticData.map(
                                                ({
                                                    id,
                                                    delay,
                                                    count,
                                                    title,
                                                }) => (
                                                    <div
                                                        className="col-lg-3 col-md-6 wow fadeInUp"
                                                        data-wow-delay={delay}
                                                        key={id}
                                                    >
                                                        <div className="fact-one__item text-center">
                                                            <SvgIcon icon="ROUNDED_DASH" />
                                                            <div className="fact-one__count">
                                                                <span className="count-box">
                                                                    <span
                                                                        className="count-text"
                                                                        data-stop="30.3"
                                                                        data-speed={
                                                                            1500
                                                                        }
                                                                    />
                                                                </span>
                                                                {count}
                                                            </div>
                                                            <h3 className="fact-one__title">
                                                                {title}
                                                            </h3>{' '}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StatisticsSection;
