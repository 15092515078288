import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { headerLinks } from '../../../utils/data';
import './Header.css';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(window.screen > 991);
    const [scrollPosition, setScrollPosition] = useState(0);
    const { pathname } = useLocation();
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const courseKey = searchParams.get('course');

    window.addEventListener('scroll', () => {
        const scroll = window.scrollY;
        setScrollPosition(scroll);
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.screen.width < 991) {
            setIsMenuOpen(true);
        }
    }, [pathname]);

    const handleListClick = (title) => {
        if (title === 'Courses') {
            setIsDropDownOpen(!isDropDownOpen);
            return;
        }
        if (window.screen.width < 991) {
            setIsMenuOpen(true);
        }
    };
    const menuRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (
                isDropDownOpen &&
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setIsDropDownOpen(false);
            }
        };

        if (isDropDownOpen) {
            document.addEventListener('mousedown', handleDocumentClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isDropDownOpen]);

    return (
        <div
            data-elementor-type="wp-post"
            data-elementor-id={972}
            className="elementor elementor-972"
        >
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-0a8429a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="0a8429a"
                data-element_type="section"
            >
                <div className="elementor-container elementor-column-gap-no">
                    <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-56aa38d"
                        data-id="56aa38d"
                        data-element_type="column"
                    >
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                                className="elementor-element elementor-element-cbfe087 elementor-widget elementor-widget-eduact-header"
                                data-id="cbfe087"
                                data-element_type="widget"
                                data-widget_type="eduact-header.default"
                            >
                                <div className="elementor-widget-container">
                                    <header className="main-header header__menu">
                                        <nav
                                            className="main-menu header__menu"
                                            style={{
                                                backgroundColor: `${scrollPosition > 70 ? '#2a254d' : 'transparent'}`,
                                            }}
                                        >
                                            <div className={`container`}>
                                                <div className="main-menu__logo logo-retina">
                                                    <Link to="/">
                                                        <img
                                                            decoding="async"
                                                            width={160}
                                                            height={48}
                                                            src="/logo.svg"
                                                            alt="Eduact"
                                                            loading="lazy"
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="main-menu__nav">
                                                    <div className="menu-main-menu-container">
                                                        <ul
                                                            ref={menuRef}
                                                            id="menu-main-menu"
                                                            className={`main-menu__list ${isMenuOpen ? 'display-none' : 'display-grid'}`}
                                                        >
                                                            {headerLinks.map(
                                                                ({
                                                                    id,
                                                                    path,
                                                                    title,
                                                                    hasChildren,
                                                                    subMenu,
                                                                }) => (
                                                                    <li
                                                                        onClick={() =>
                                                                            handleListClick(
                                                                                title
                                                                            )
                                                                        }
                                                                        key={id}
                                                                        className={`menu-item menu-item-type-post_type menu-item-object-page ${hasChildren && !isDropDownOpen ? 'menu-item-has-children' : 'menu-item-has-children-close'} megamenu-hide menu-item-928`}
                                                                    >
                                                                        {title ===
                                                                        'Courses' ? (
                                                                            <span className="menu-item-link cursor-pointer">
                                                                                {
                                                                                    title
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <Link
                                                                                className="menu-item-link"
                                                                                to={
                                                                                    path
                                                                                }
                                                                            >
                                                                                {
                                                                                    title
                                                                                }
                                                                            </Link>
                                                                        )}
                                                                        {subMenu.length >
                                                                            0 &&
                                                                            isDropDownOpen && (
                                                                                <ul className="sub-menu">
                                                                                    {subMenu.map(
                                                                                        ({
                                                                                            id,
                                                                                            path,
                                                                                            title,
                                                                                            key,
                                                                                        }) => (
                                                                                            <li
                                                                                                key={
                                                                                                    id
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        window
                                                                                                            .screen
                                                                                                            .width <
                                                                                                        991
                                                                                                    ) {
                                                                                                        setIsMenuOpen(
                                                                                                            true
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-906"
                                                                                            >
                                                                                                <Link
                                                                                                    className={`menu-item-link ${courseKey === key && 'text-orange'}`}
                                                                                                    to={
                                                                                                        path
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        title
                                                                                                    }
                                                                                                </Link>
                                                                                            </li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            )}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="main-menu__right">
                                                    <button
                                                        className="main-menu__toggler mobile-nav__toggler"
                                                        style={{
                                                            color: 'white',
                                                            backgroundColor:"transparent",
                                                            border:"none"
                                                        }}
                                                        onClick={() =>
                                                            setIsMenuOpen(
                                                                !isMenuOpen
                                                            )
                                                        }
                                                    >
                                                        {!isMenuOpen ? (
                                                            <i className="fa fa-times" />
                                                        ) : (
                                                            <i className="fa fa-bars" />
                                                        )}
                                                    </button>

                                                    <Link
                                                        to="/contact"
                                                        className="eduact-btn"
                                                    >
                                                        <span className="eduact-btn__curve" />
                                                        Get In Touch{' '}
                                                    </Link>
                                                </div>
                                            </div>
                                        </nav>
                                    </header>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Header;
