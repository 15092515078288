import { serviceCardData } from '../../utils/data';
import { Link } from 'react-router-dom';

const ServiceCards = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-15eb6c4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="15eb6c4"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9a8d1e"
                    data-id="b9a8d1e"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-c8f140c elementor-widget elementor-widget-eduact-service"
                            data-id="c8f140c"
                            data-element_type="widget"
                            data-widget_type="eduact-service.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="service-one">
                                    <div
                                        className="service-one__bg"
                                        style={{
                                            backgroundImage:
                                                'url(../wp-content/uploads/2023/09/service-bg-1-dark.png)',
                                        }}
                                    />
                                    <div className="container">
                                        <div className="row">
                                            {/* <h5
                                                className="section-title__tagline"
                                                style={{
                                                    textAlign: 'center',
                                                    color: '#f57005',
                                                }}
                                            >
                                                Courses
                                            </h5>

                                            <p
                                                className="section-title__title"
                                                style={{
                                                    fontSize: '40px',
                                                    textAlign: 'center',
                                                    fontWeight: '900',
                                                    padding: '0 0 10px 0',
                                                }}
                                            >
                                                Pick The Right Course To Build
                                                Your Career
                                            </p>*/}
                                            {serviceCardData.map(
                                                ({
                                                    id,
                                                    delay,
                                                    icon,
                                                    link,
                                                    title,
                                                    content,
                                                }) => (
                                                    <div
                                                        key={id}
                                                        className="col-lg-3 col-md-6 wow fadeInUp"
                                                        data-wow-delay={delay}
                                                    >
                                                        <div className="service-one__item">
                                                            <div className="service-one__wrapper">
                                                                <div className="service-one__icon icon-svg-large">
                                                                    {/*<SvgIcon icon="WEB_DEVELOPMENT" />*/}
                                                                    <img
                                                                        src={
                                                                            icon
                                                                        }
                                                                        alt="icon"
                                                                        style={{
                                                                            margin: 'auto',
                                                                            width: '55px',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <h3 className="service-one__title">
                                                                    <Link
                                                                        to={
                                                                            link
                                                                        }
                                                                    >
                                                                        {title}
                                                                    </Link>
                                                                </h3>
                                                                <p className="service-one__text  clamp-text">
                                                                    {content}
                                                                </p>
                                                                {/*<Link
                                                                    className="service-one__rm"
                                                                    to={link}
                                                                >
                                                                    Read More
                                                                    <span className="icon-caret-right" />
                                                                </Link>
                                                                <SvgIcon icon="SERVICE_SECTION" />*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceCards;
