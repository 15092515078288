import SvgIcon from '../common/Icon/SvgIcon';
import { missionVisionData } from '../../utils/data';

const MissionVisionSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-26bbd73 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="26bbd73"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b5c861a"
                    data-id="b5c861a"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-e38f017 elementor-widget elementor-widget-eduact-about"
                            data-id="e38f017"
                            data-element_type="widget"
                            data-widget_type="eduact-about.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="about-two about-two--about">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div
                                                    className="about-two__thumb wow fadeInLeft"
                                                    data-wow-delay="100ms"
                                                >
                                                    <div
                                                        className="about-two__thumb__one eduact-tilt"
                                                        data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 2, "speed": 700, "scale": 1 }'
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-2-2-about-1.jpg"
                                                            alt="alt"
                                                            title="about-2-2-about"
                                                        />
                                                    </div>
                                                    <div className="about-two__thumb__two">
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-2-1-about-1.jpg"
                                                            alt="alt"
                                                            title="about-2-1-about"
                                                        />
                                                        <div className="about-two__thumb__two-icon">
                                                            <span
                                                                aria-hidden="true"
                                                                className="  icon-business"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="about-two__fact">
                                                        <div className="about-two__fact__icon">
                                                            <span
                                                                aria-hidden="true"
                                                                className="  icon-trophy"
                                                            />
                                                        </div>
                                                        <div className="about-two__fact__content">
                                                            <div className="about-two__fact__count">
                                                                +
                                                                <span className="count-box">
                                                                    <span
                                                                        className="count-text"
                                                                        data-stop={
                                                                            320
                                                                        }
                                                                        data-speed={
                                                                            1500
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                            <h3 className="about-two__fact__title">
                                                                Awesome Awards
                                                            </h3>
                                                        </div>
                                                    </div>*/}
                                                    <div
                                                        className="about-two__thumb__shape1 wow zoomIn"
                                                        data-wow-delay="300ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-2-shape-1-2.png"
                                                            alt="alt"
                                                            title="about-2-shape-1"
                                                        />{' '}
                                                    </div>
                                                    <div
                                                        className="about-two__thumb__shape2 wow zoomIn"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-2-shape-2-2.png"
                                                            alt="alt"
                                                            title="about-2-shape-2"
                                                        />{' '}
                                                    </div>
                                                    <div
                                                        className="about-two__thumb__shape3 wow zoomIn"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-2-shape-3-2.png"
                                                            alt="alt"
                                                            title="about-2-shape-3"
                                                        />{' '}
                                                    </div>
                                                    <div
                                                        className="about-two__thumb__shape4 wow zoomIn"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-2-shape-4-2.png"
                                                            alt="alt"
                                                            title="about-2-shape-4"
                                                        />{' '}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-xl-6 wow fadeInRight"
                                                data-wow-delay="100ms"
                                            >
                                                <div className="about-two__content">
                                                    <div className="section-title">
                                                        <h5 className="section-title__tagline">
                                                            About Us
                                                            <SvgIcon icon="FOUR_ARROW" />
                                                        </h5>
                                                        <h2 className="section-title__title text-black">
                                                            Pioneering
                                                            Excellence in
                                                            Technological
                                                            Innovation and
                                                            Education
                                                        </h2>
                                                    </div>
                                                    <p className="about-two__content__text text-black">
                                                        Creative Insight IT
                                                        Academy nurtures
                                                        innovation and fosters
                                                        tomorrow's tech leaders
                                                        through personalized
                                                        education and real-world
                                                        experience.
                                                    </p>
                                                    {}
                                                    {missionVisionData.map(
                                                        ({
                                                            id,
                                                            title,
                                                            description,
                                                            icon,
                                                        }) => (
                                                            <div
                                                                className="about-two__about-box"
                                                                key={id}
                                                            >
                                                                <div className="about-two__about-box__top">
                                                                    <div className="about-two__about-box__icon">
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={
                                                                                icon
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <h4 className="about-two__about-box__title text-black">
                                                                        {title}
                                                                    </h4>
                                                                </div>
                                                                <p className="about-two__about-box__text text-black">
                                                                    {
                                                                        description
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionVisionSection;
