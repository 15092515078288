const ContactDetails = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-1b8c266 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="1b8c266"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-792b4cd"
                    data-id="792b4cd"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-355489d elementor-widget elementor-widget-eduact-icon-box"
                            data-id="355489d"
                            data-element_type="widget"
                            data-widget_type="eduact-icon-box.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="contact-info">
                                    <div className="container">
                                        <ul
                                            className="contact-info__wrapper text-gray"
                                            style={{ backgroundColor: 'white' }}
                                        >
                                            <li className="">
                                                <div className="contact-info__icon icon-svg-large">
                                                    <span
                                                        aria-hidden="true"
                                                        className="  icon-Call"
                                                    />{' '}
                                                </div>
                                                <p className="contact-info__title">
                                                    Have any question?
                                                </p>
                                                <h4 className="contact-info__text text-black">
                                                    <a href="tel:9601917960">
                                                        +91 9601917960
                                                    </a>
                                                </h4>
                                            </li>
                                            <li>
                                                <div className="contact-info__icon icon-svg-large">
                                                    <span
                                                        aria-hidden="true"
                                                        className="   icon-Email"
                                                    />{' '}
                                                </div>
                                                <p className="contact-info__title text-gray">
                                                    Send Email
                                                </p>
                                                <h4
                                                    className="contact-info__text text-black"
                                                    style={{
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    <a href="mailto:info@creativeinsightacademy.com">
                                                        info@creativeinsightacademy.com
                                                    </a>
                                                </h4>
                                            </li>
                                            <li className="">
                                                <div className="contact-info__icon icon-svg-large">
                                                    <span
                                                        aria-hidden="true"
                                                        className="icon-Location"
                                                    />{' '}
                                                </div>
                                                <p className="contact-info__title text-gray">
                                                    Visit Anytime
                                                </p>
                                                <h4 className="contact-info__text text-black">
                                                    801, RK Empire, 150 Ft. Ring
                                                    Road, Nr. Mavdi Circle,
                                                    Rajkot - 360004
                                                </h4>
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ContactDetails;
