import { useRef, useState } from 'react';
import { ValidateForm, validateEmail } from '../../utils/utils';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const ContactForm = () => {
    const defaultData = {
        name: '',
        email: '',
        phone: '',
        message: '',
        subject: '',
    };
    const [formData, setFormData] = useState(defaultData);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    const handleSend = (e) => {
        e.preventDefault();
        if (!ValidateForm(formData)) {
            if (validateEmail(formData.email)) {
                sendEmail(e);
                setFormData(defaultData);
                toast.success('Message send successfully');
            } else {
                toast.error('Please enter valid email address');
            }
        } else {
            toast.error('Please fill all details');
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const SERVICE_KEY = 'service_oocwplk';
        const PUBLIC_KEY = 'AhFM95oY3_ixp7jIe';
        const TEMPLATE_ID = 'template_2sctxkq';

        emailjs
            .sendForm(SERVICE_KEY, TEMPLATE_ID, form.current, PUBLIC_KEY)
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    const form = useRef(null);

    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-1fee4b2 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="1fee4b2"
            data-element_type="section"
        >
            <Toaster />
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ef4425a"
                    data-id="ef4425a"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-fe34460 elementor-widget elementor-widget-eduact-contact-form"
                            data-id="fe34460"
                            data-element_type="widget"
                            data-widget_type="eduact-contact-form.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="contact-one">
                                    <div
                                        className="container wow fadeInUp"
                                        data-wow-delay="300ms"
                                    >
                                        <div className="section-title  text-center">
                                            <h5 className="section-title__tagline">
                                                Contact with Us{' '}
                                                <svg
                                                    className="arrow-svg"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 55 13"
                                                >
                                                    <g clipPath="url(#clip0_324_36194)">
                                                        <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                                                        <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                                                        <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                                                        <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                                                    </g>
                                                </svg>
                                            </h5>
                                            <h2 className="section-title__title text-black">
                                                Feel Free to Write us Anytime
                                            </h2>
                                        </div>
                                        <div className="contact-one__form-box  text-center">
                                            <div
                                                className="wpcf7 no-js"
                                                id="wpcf7-f411-p52-o1"
                                                lang="en-US"
                                                dir="ltr"
                                            >
                                                <div className="screen-reader-response">
                                                    <p
                                                        role="status"
                                                        aria-live="polite"
                                                        aria-atomic="true"
                                                    />{' '}
                                                    <ul />
                                                </div>
                                                <form
                                                    className="wpcf7-form init"
                                                    aria-label="Contact form"
                                                    autoComplete="off"
                                                    method="post"
                                                    ref={form}
                                                >
                                                    <div className="contact-one__form contact-form-validated">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="contact-one__input-box">
                                                                    <span
                                                                        className="wpcf7-form-control-wrap"
                                                                        data-name="text-667"
                                                                    >
                                                                        <input
                                                                            size={
                                                                                40
                                                                            }
                                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-black"
                                                                            aria-required="true"
                                                                            aria-invalid="false"
                                                                            placeholder="Your Name"
                                                                            value={
                                                                                formData.name
                                                                            }
                                                                            type="text"
                                                                            name="name"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="contact-one__input-box">
                                                                    <span
                                                                        className="wpcf7-form-control-wrap"
                                                                        data-name="email-942"
                                                                    >
                                                                        <input
                                                                            size={
                                                                                40
                                                                            }
                                                                            className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email text-black"
                                                                            aria-required="true"
                                                                            aria-invalid="false"
                                                                            placeholder="Email Address"
                                                                            value={
                                                                                formData.email
                                                                            }
                                                                            type="email"
                                                                            name="email"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="contact-one__input-box">
                                                                    <span
                                                                        className="wpcf7-form-control-wrap"
                                                                        data-name="phone"
                                                                    >
                                                                        <input
                                                                            size={
                                                                                40
                                                                            }
                                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-black"
                                                                            aria-required="true"
                                                                            aria-invalid="false"
                                                                            placeholder="Phone"
                                                                            value={
                                                                                formData.phone
                                                                            }
                                                                            type="text"
                                                                            name="phone"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="contact-one__input-box">
                                                                    <span
                                                                        className="wpcf7-form-control-wrap"
                                                                        data-name="subject"
                                                                    >
                                                                        <input
                                                                            size={
                                                                                40
                                                                            }
                                                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-black"
                                                                            aria-required="true"
                                                                            aria-invalid="false"
                                                                            placeholder="Subject"
                                                                            value={
                                                                                formData.subject
                                                                            }
                                                                            type="text"
                                                                            name="subject"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="contact-one__input-box text-message-box">
                                                                    <span
                                                                        className="wpcf7-form-control-wrap"
                                                                        data-name="textarea-670"
                                                                    >
                                                                        <textarea
                                                                            cols={
                                                                                40
                                                                            }
                                                                            rows={
                                                                                10
                                                                            }
                                                                            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required text-black"
                                                                            aria-required="true"
                                                                            aria-invalid="false"
                                                                            placeholder="Write a Message"
                                                                            name="message"
                                                                            value={
                                                                                formData.message
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="contact-one__btn-box">
                                                                    <button
                                                                        className="eduact-btn eduact-btn-second"
                                                                        onClick={
                                                                            handleSend
                                                                        }
                                                                    >
                                                                        <span className="eduact-btn__curve" />
                                                                        Send a
                                                                        Message
                                                                        <i className="icon-arrow" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="wpcf7-response-output"
                                                        aria-hidden="true"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
