export const ValidateForm = (data) => {
    for (const key in data) {
        if (data.hasOwnProperty(key) && data[key] === '') {
            return true;
        }
    }
    return false;
};
export const validateEmail = (input) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(input);
};
