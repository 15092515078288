import Layout from '../components/common/Layout';
import Breadcrumb from '../components/common/Breadcrumb';
import { fullCourseDetails } from '../utils/data';
import { Link } from 'react-router-dom';
import "../assets/css/courses.css";

const Courses = () => {
    return (
        <Layout>
            <h1 style={{display:"none"}}>Creative Insight</h1>
            <Breadcrumb title="COURSE PAGE" startPath="Creative Insight" endPath="courses" />
                <div
                    data-elementor-type="wp-page"
                    data-elementor-id={46}
                    className="elementor elementor-46"
                >
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-742f564 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                        data-id="742f564"
                        data-element_type="section"
                    >
                        <div className="elementor-container elementor-column-gap-no">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3457a6d"
                                data-id="3457a6d"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-88d9cb8 elementor-widget elementor-widget-eduact-course"
                                        data-id="88d9cb8"
                                        data-element_type="widget"
                                        data-widget_type="eduact-course.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <section className="course-two course-two--page">
                                                <div
                                                    className="course-two__shape-top wow fadeInRight"
                                                    data-wow-delay="300ms"
                                                >
                                                    <img
                                                        decoding="async"
                                                        src="/wp-content/uploads/2023/09/course-shape-1-1.png"
                                                        alt="alt"
                                                        title="course-shape-1"
                                                    />{" "}
                                                </div>
                                                <div className="container">
                                                    <div className="row">
                                                        {
                                                            fullCourseDetails.map(({id,title,link,imagePath})=>(
                                                                <div
                                                                    key={id}
                                                                    className="col-xl-4 col-md-6 wow fadeInUp"
                                                                    data-wow-delay="100ms"
                                                                >
                                                                    <div className="course-two__item">
                                                                        <div className="course-two__thumb">
                                                                            <img
                                                                                decoding="async"
                                                                                width={416}
                                                                                height={276}
                                                                                src={imagePath}
                                                                                className="attachment-eduact_course_416X276 size-eduact_course_416X276 wp-post-image element-image"
                                                                                alt={title}
                                                                            />
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 353 177"
                                                                            >
                                                                                <path d="M37 0C16.5655 0 0 16.5655 0 37V93.4816C0 103.547 4.00259 113.295 11.7361 119.737C54.2735 155.171 112.403 177 176.496 177C240.589 177 298.718 155.171 341.261 119.737C348.996 113.295 353 103.546 353 93.4795V37C353 16.5655 336.435 0 316 0H37Z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className="course-two__content">
                                                                            <h3 className="course-two__title">
                                                                                <Link to={link}>
                                                                                    {title}
                                                                                </Link>
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                                <div
                                                    className="course-two__shape-bottom wow fadeInLeft"
                                                    data-wow-delay="400ms"
                                                >
                                                    <img
                                                        decoding="async"
                                                        src="/wp-content/uploads/2023/09/course-shape-2-1.png"
                                                        alt="alt"
                                                        title="course-shape-2"
                                                    />{" "}
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        </Layout>

    )
}

export default Courses