import { favoriteTopicsData } from '../../utils/data';
import { Link } from 'react-router-dom';

const BestCoursesSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-728cfc28 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="728cfc28"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7428dddc"
                    data-id="7428dddc"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-8ed21b elementor-widget elementor-widget-eduact-course"
                            data-id="8ed21b"
                            data-element_type="widget"
                            data-widget_type="eduact-course.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="course-two">
                                    <div
                                        className="course-two__shape-top wow fadeInRight"
                                        data-wow-delay="300ms"
                                    >
                                        <img
                                            decoding="async"
                                            src="/wp-content/uploads/2023/09/course-shape-1.png"
                                            alt="alt"
                                            title="course-shape-1"
                                        />{' '}
                                    </div>
                                    <div
                                        className="container wow fadeInUp"
                                        data-wow-delay="200ms"
                                    >
                                        <div className="section-title text-center">
                                            <h5 className="section-title__tagline">
                                                Best Course{' '}
                                                <svg
                                                    className="arrow-svg"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 55 13"
                                                >
                                                    <g clipPath="url(#clip0_324_36194)">
                                                        <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                                                        <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                                                        <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                                                        <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                                                    </g>
                                                </svg>
                                            </h5>
                                            <h2 className="section-title__title text-black">
                                                Featured Course On This Month{' '}
                                            </h2>
                                        </div>
                                        <div className="service-card-wrapper">
                                            {favoriteTopicsData.map(
                                                ({
                                                    id,
                                                    icon,
                                                    title,
                                                    courseCount,
                                                    hoverImagePath,
                                                    imagePath,
                                                    link,
                                                }) => (
                                                    <div
                                                        key={id}
                                                        className="item service-card"
                                                    >
                                                        <div className="category-one__item">
                                                            <div
                                                                className="category-one__wrapper"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url(/wp-content/uploads/2023/09/category-shape.png)',
                                                                }}
                                                            >
                                                                <div className="category-one__thumb">
                                                                    <img
                                                                        decoding="async"
                                                                        src={
                                                                            imagePath
                                                                        }
                                                                        alt="category-normal-1"
                                                                        title="category-normal-1"
                                                                    />{' '}
                                                                </div>
                                                                <div className="category-one__content">
                                                                    <div className="category-one__icon">
                                                                        <img
                                                                            src={
                                                                                icon
                                                                            }
                                                                            alt="icon"
                                                                            style={{
                                                                                margin: 'auto',
                                                                                width: '55px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <h3 className="category-one__title">
                                                                        {title}
                                                                    </h3>
                                                                    {/*<p className="category-one__text">*/}
                                                                    {/*    {*/}
                                                                    {/*        courseCount*/}
                                                                    {/*    }{' '}*/}
                                                                    {/*    Courses*/}
                                                                    {/*</p>*/}
                                                                </div>
                                                            </div>
                                                            <div className="category-one__hover">
                                                                <div className="category-one__hover__thumb">
                                                                    <img
                                                                        decoding="async"
                                                                        src={
                                                                            hoverImagePath
                                                                        }
                                                                        alt="alt"
                                                                        title="category-hover-1"
                                                                    />{' '}
                                                                </div>
                                                                <div className="category-one__hover__content">
                                                                    <div className="category-one__hover__icon">
                                                                        <img
                                                                            src={
                                                                                icon
                                                                            }
                                                                            alt="icon"
                                                                            style={{
                                                                                margin: 'auto',
                                                                                width: '55px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <h3 className="category-one__hover__title">
                                                                        <Link
                                                                            to={
                                                                                link
                                                                            }
                                                                        >
                                                                            {
                                                                                title
                                                                            }
                                                                        </Link>
                                                                    </h3>
                                                                    {/*<p className="category-one__hover__text">*/}
                                                                    {/*    {*/}
                                                                    {/*        courseCount*/}
                                                                    {/*    }{' '}*/}
                                                                    {/*    Courses*/}
                                                                    {/*</p>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="course-two__shape-bottom wow fadeInLeft"
                                        data-wow-delay="400ms"
                                    >
                                        <img
                                            decoding="async"
                                            src="/wp-content/uploads/2023/09/course-shape-2.png"
                                            alt="alt"
                                            title="course-shape-2"
                                        />{' '}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default BestCoursesSection;
