import Layout from '../components/common/Layout';
import Breadcrumb from '../components/common/Breadcrumb';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { fullCourseDetails } from '../utils/data';
import { useEffect, useState } from 'react';

const CourseDetail = () => {
    const [searchParams] = useSearchParams();
    const courseKey = searchParams.get('course');
    const [course, setCourse] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const [filteredCourse] = fullCourseDetails.filter(
            (course) => course.key === courseKey
        );
        setCourse(filteredCourse);
        window.scrollTo(0, 0);
    }, [searchParams, courseKey]);

    if (!courseKey) {
        navigate('/courses');
    }

    return (
        <Layout>
            <Breadcrumb
                title={course?.title.toUpperCase()}
                startPath="Creative Insight"
                endPath="courses"
            />
            <h1 style={{display:"none"}}>Creative Insight</h1>
            <div
                data-elementor-type="wp-post"
                data-elementor-id={635}
                className="elementor elementor-635"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-625761a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="625761a"
                    data-element_type="section"
                >
                    <div className="elementor-container elementor-column-gap-default">
                        <div
                            className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-9b2ff62"
                            data-id="9b2ff62"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                    className="elementor-element elementor-element-676a49f elementor-widget elementor-widget-eduact-course-details"
                                    data-id="676a49f"
                                    data-element_type="widget"
                                    data-widget_type="eduact-course-details.default"
                                >
                                    <div className="elementor-widget-container">
                                        <div className="course-details__thumb">
                                            <img
                                                decoding="async"
                                                width={868}
                                                height={449}
                                                src={course?.imagePath || ''}
                                                className="attachment-eduact_course_868X450 size-eduact_course_868X450 wp-post-image"
                                                alt={course?.title}
                                                sizes="(max-width: 868px) 100vw, 868px"
                                            />{' '}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="elementor-element elementor-element-39912a2 elementor-widget elementor-widget-eduact-course-details"
                                    data-id="39912a2"
                                    data-element_type="widget"
                                    data-widget_type="eduact-course-details.default"
                                >
                                    <div className="elementor-widget-container">
                                        <section className="course-details">
                                            <div className="course-details__tabs tabs-box">
                                                <div className="tabs-content">
                                                    <div
                                                        className="tab fadeInUp animated active-tab"
                                                        id="curriculum"
                                                    >
                                                        <div className="course-details__curriculum">
                                                            <h4 className="course-details__curriculum__title text-orange">
                                                                Course
                                                                Description
                                                            </h4>
                                                            <p className="course-details__curriculum__text text-black">
                                                                {
                                                                    course?.description
                                                                }
                                                            </p>
                                                            <div className="tutor-accordion tutor-mt-24">
                                                                <div className="tutor-accordion-item">
                                                                    <h4 className="tutor-accordion-item-header is-active">
                                                                        {
                                                                            course?.subTitle
                                                                        }
                                                                    </h4>
                                                                    <div className="tutor-accordion-item-body">
                                                                        <div className="tutor-accordion-item-body-content">
                                                                            <ul className="tutor-course-content-list">
                                                                                {course?.concepts?.map(
                                                                                    (
                                                                                        data,
                                                                                        i
                                                                                    ) => {
                                                                                        return (
                                                                                            <li
                                                                                                key={
                                                                                                    i
                                                                                                }
                                                                                                className="tutor-course-content-list-item"
                                                                                            >
                                                                                                <div className="tutor-d-flex tutor-align-center">
                                                                                                    <h5 className="tutor-course-content-list-item-title text-black">
                                                                                                        {
                                                                                                            data
                                                                                                        }
                                                                                                    </h5>
                                                                                                </div>
                                                                                                <div></div>
                                                                                            </li>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-cdf7546"
                            data-id="cdf7546"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                    className="elementor-element elementor-element-05d9190 elementor-widget elementor-widget-eduact-course-sidebar"
                                    data-id="05d9190"
                                    data-element_type="widget"
                                    data-widget_type="eduact-course-sidebar.default"
                                >
                                    <div className="elementor-widget-container">
                                        <div className="course-details__sidebar__item">
                                            <h3 className="course-details__sidebar__title">
                                                Course Features{' '}
                                            </h3>
                                            <ul className="course-details__sidebar__lists clerfix">
                                                <li>
                                                    <i
                                                        aria-hidden="true"
                                                        className="  icon-history"
                                                    />{' '}
                                                    Duration:
                                                    <span>
                                                        {course?.duration}
                                                    </span>
                                                </li>
                                                <li>
                                                    <i
                                                        aria-hidden="true"
                                                        className="   icon-logical-thinking"
                                                    />{' '}
                                                    Skill Level
                                                    <span>
                                                        {course?.skillLevel}
                                                    </span>
                                                </li>
                                                <li>
                                                    <i
                                                        aria-hidden="true"
                                                        className="   icon-Digital-marketing"
                                                    />{' '}
                                                    Language
                                                    <span>
                                                        {course?.language}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="elementor-element elementor-element-292a903 elementor-widget elementor-widget-eduact-course-sidebar"
                                    data-id="292a903"
                                    data-element_type="widget"
                                    data-widget_type="eduact-course-sidebar.default"
                                >
                                    <div className="elementor-widget-container">
                                        <div className="course-details__sidebar__item">
                                            <h3 className="course-details__sidebar__title">
                                                You May Like
                                            </h3>
                                            <ul className="course-details__sidebar__post">
                                                {fullCourseDetails.map(
                                                    ({
                                                        id,
                                                        imagePath,
                                                        link,
                                                        key,
                                                        title,
                                                    }) => (
                                                        <li key={id}>
                                                            <div className="course-details__sidebar__post__image">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={89}
                                                                    height={89}
                                                                    src={
                                                                        imagePath
                                                                    }
                                                                    className="attachment-eduact_course_89X89 size-eduact_course_89X89 wp-post-image"
                                                                    alt="at"
                                                                />{' '}
                                                            </div>
                                                            <div className="course-details__sidebar__post__content">
                                                                <span className="course-details__sidebar__post__meta">
                                                                    {title}
                                                                </span>
                                                                <h3 className="course-details__sidebar__post__title">
                                                                    <Link
                                                                        to={
                                                                            link
                                                                        }
                                                                    >
                                                                        {title}{' '}
                                                                        Course
                                                                    </Link>
                                                                </h3>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default CourseDetail;
