import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import HomePage from './pages/Home';
import About from './pages/About';
import Courses from './pages/Courses';
import ContactPage from './pages/Contact';
import CourseDetail from './pages/CourseDetail';


function App() {


    return (
        <BrowserRouter>
            <Routes>
                <Route Component={HomePage} path="/" />
                <Route Component={About} path="/about" />
                <Route Component={Courses} path="/courses" />
                <Route Component={CourseDetail} path="/course" />
                <Route Component={ContactPage} path="/contact" />
                <Route Component={HomePage} path="/events" />
                <Route Component={HomePage} path="*" />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
