import { testimonialData } from '../../utils/data';

const TestimonialSection = () => {
    return(
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-7ae1172b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="7ae1172b"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-557af666"
                    data-id="557af666"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-1df71d5f elementor-widget elementor-widget-eduact-testimonials"
                            data-id="1df71d5f"
                            data-element_type="widget"
                            data-widget_type="eduact-testimonials.default"
                        >
                            <div className="elementor-widget-container">
                                <section
                                    className="testimonial-two"
                                    style={{
                                        backgroundImage:
                                            'url(../wp-content/uploads/2023/09/testimonial-bg-2-1.jpg)',
                                    }}
                                >
                                    <div className="container">
                                        <div className="section-title text-center">
                                            <h5 className="section-title__tagline">
                                                Testimonial{' '}
                                                <svg
                                                    className="arrow-svg"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 55 13"
                                                >
                                                    <g clipPath="url(#clip0_324_36194)">
                                                        <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                                                        <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                                                        <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                                                        <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                                                    </g>
                                                </svg>
                                            </h5>
                                            <h2 className="section-title__title text-black">
                                                What Our Student
                                                Feedback
                                            </h2>
                                        </div>
                                        <div
                                            className="testimonial-two__carousel eduact-owl__carousel owl-with-shadow owl-theme" >
                                            <div className="testimonial-wrapper">
                                                {testimonialData.map(
                                                    ({
                                                         id,
                                                         name,
                                                         role,
                                                         review,
                                                         studentImage,
                                                     }) => (
                                                        <div
                                                            key={id}
                                                            className="testimonial-card "
                                                        >
                                                            <div className="testimonial-one__item testimonial-one__area">
                                                                <div className="testimonial-one__author">
                                                                    <img
                                                                        decoding="async"
                                                                        src={
                                                                            studentImage
                                                                        }
                                                                        alt={
                                                                            name
                                                                        }
                                                                        title={
                                                                            name
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="testimonial-one__content">
                                                                    <div className="testimonial-one__quote clamp-text" title={review}>
                                                                        {review}
                                                                    </div>
                                                                    <div className="testimonial-one__meta">
                                                                        <h5 className="testimonial-one__title">
                                                                            {
                                                                                name
                                                                            }
                                                                        </h5>
                                                                        <span className="testimonial-one__designation">
                                                                            {
                                                                                role
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialSection