import Title from '../common/Title';
import { testimonialData } from '../../utils/data';

const TestimonialsSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-fe207fc elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="fe207fc"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-60f2bfa"
                    data-id="60f2bfa"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-7cc015b elementor-widget elementor-widget-eduact-testimonials"
                            data-id="7cc015b"
                            data-element_type="widget"
                            data-widget_type="eduact-testimonials.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="testimonial-one">
                                    <div className="container">
                                        <div className="section-title text-center">
                                            <Title title="Testimonial" />
                                            <h2 className="section-title__title">
                                                What Our Student Feedback
                                            </h2>
                                        </div>
                                        <div className="">
                                            <div className="testimonial-wrapper">
                                                {testimonialData.map(
                                                    ({
                                                        id,
                                                        name,
                                                        role,
                                                        review,
                                                        studentImage,
                                                    }) => (
                                                        <div
                                                            key={id}
                                                            className="testimonial-card"

                                                        >
                                                            <div className="testimonial-one__item testimonial-one__area"  style={{minHeight:"520px"}}>
                                                                <div className="testimonial-one__author">
                                                                    <img
                                                                        decoding="async"
                                                                        src={
                                                                            studentImage
                                                                        }
                                                                        alt={
                                                                            name
                                                                        }
                                                                        title={
                                                                            name
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="testimonial-one__content">
                                                                    <div
                                                                        className="testimonial-one__quote clamp-text"
                                                                        title={
                                                                            review
                                                                        }
                                                                    >
                                                                        {review}
                                                                    </div>
                                                                    <div className="testimonial-one__meta">
                                                                        <h5 className="testimonial-one__title">
                                                                            {
                                                                                name
                                                                            }
                                                                        </h5>
                                                                        <span className="testimonial-one__designation">
                                                                            {
                                                                                role
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {/*   <div
                                                className="testimonial-one__thumb wow fadeInUp"
                                                data-wow-delay="200ms"
                                            >
                                                <img
                                                    decoding="async"
                                                    src="/wp-content/uploads/2023/09/testimonial-1.png"
                                                    alt="alt"
                                                    title="testimonial-1"
                                                />
                                                <SvgIcon icon="TESTIMONIAL_SECTION" />
                                                <div
                                                    className="testimonial-one__thumb-pen wow fadeInUp"
                                                    data-wow-delay="400ms"
                                                >
                                                    <img
                                                        decoding="async"
                                                        src="/wp-content/uploads/2023/09/testimonial-shape-1.png"
                                                        alt="testimonial-shape-1"
                                                        title="testimonial-shape-1"
                                                    />
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
