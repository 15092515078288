import { Link } from 'react-router-dom';
import Title from '../common/Title';
import { aboutUsData } from '../../utils/data';

const AboutSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-2bbcd30 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="2bbcd30"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2d6d5c2"
                    data-id="2d6d5c2"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-242d341 elementor-widget elementor-widget-eduact-about"
                            data-id="242d341"
                            data-element_type="widget"
                            data-widget_type="eduact-about.default"
                        >
                            <div className="elementor-widget-container">
                                <section className="about-one">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div
                                                    className="about-one__thumb wow fadeInLeft"
                                                    data-wow-delay="100ms"
                                                >
                                                    <div
                                                        className="about-one__thumb__one eduact-tilt"
                                                        data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 2, "speed": 700, "scale": 1 }'
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-1-1.png"
                                                            alt="alt"
                                                            title="about-1-1"
                                                        />
                                                    </div>
                                                    <div
                                                        className="about-one__thumb__shape1 wow zoomIn"
                                                        data-wow-delay="300ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-shape-1-1.png"
                                                            alt="alt"
                                                            title="about-shape-1-1"
                                                        />
                                                    </div>
                                                    <div
                                                        className="about-one__thumb__shape2 wow zoomIn"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/about-shape-1-2.png"
                                                            alt="alt"
                                                            title="about-shape-1-2"
                                                        />
                                                    </div>
                                                    <div
                                                        className="about-one__thumb__box wow fadeInLeft"
                                                        data-wow-delay="600ms"
                                                    >
                                                        <div className="about-one__thumb__box__icon">
                                                            <span
                                                                aria-hidden="true"
                                                                className="  icon-Headphone-Women"
                                                            />
                                                        </div>
                                                        <h4 className="about-one__thumb__box__title">
                                                            Need to Know More
                                                            Details?
                                                        </h4>
                                                        <p className="about-one__thumb__box__text">
                                                            <a
                                                                href="tel:9601917960"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                +91 9601917960
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="about-one__content">
                                                    <div className="section-title">
                                                        <Title title="About Us" />

                                                        <h2 className="section-title__title">
                                                            Pioneering
                                                            Excellence in
                                                            Technological
                                                            Innovation and
                                                            Education
                                                        </h2>
                                                    </div>
                                                    <p className="about-one__content__text">
                                                        Creative Insight IT
                                                        Academy nurtures
                                                        innovation and fosters
                                                        tomorrow's tech leaders
                                                        through personalized
                                                        education and real-world
                                                        experience.
                                                    </p>
                                                    {aboutUsData.map(
                                                        ({
                                                            id,
                                                            title,
                                                            icon,
                                                        }) => (
                                                            <div
                                                                key={id}
                                                                className="about-one__box"
                                                                style={{
                                                                    maxWidth:
                                                                        '500px',
                                                                }}
                                                            >
                                                                <div
                                                                    className="about-one__box__wrapper"
                                                                    style={{
                                                                        margin: '0px',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="about-one__box__icon icon-svg-large"
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '30px',
                                                                            background:
                                                                                'transparent',
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                icon
                                                                            }
                                                                            alt="icon"
                                                                        />
                                                                    </div>
                                                                    <h4 className="about-one__box__title">
                                                                        {title}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                    <Link
                                                        to="/about"
                                                        className="eduact-btn"
                                                    >
                                                        <span className="eduact-btn__curve" />
                                                        Discover More
                                                        <i className="icon-arrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
