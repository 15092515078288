import { Link } from 'react-router-dom';
import {
    footerExploreLinks,
    footerQuickLinks,
    footerSocialLinks,
} from '../../utils/data';

const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div
                data-elementor-type="wp-post"
                data-elementor-id={1007}
                className="elementor elementor-1007"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-644588d0 elementor-section-full_width main-footer elementor-section-height-default elementor-section-height-default"
                    data-id="644588d0"
                    data-element_type="section"
                >
                    <div className="elementor-container elementor-column-gap-no">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6753e796"
                            data-id="6753e796"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                    className="elementor-element elementor-element-1f54cced elementor-widget elementor-widget-eduact-shape"
                                    data-id="1f54cced"
                                    data-element_type="widget"
                                    data-widget_type="eduact-shape.default"
                                >
                                    <div className="elementor-widget-container">
                                        <div
                                            className="main-footer__bg"
                                            style={{
                                                backgroundImage:
                                                    'url(/wp-content/uploads/2023/09/footer-bg-1.png)',
                                            }}
                                        />
                                    </div>
                                </div>
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-1d3ac3b1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="1d3ac3b1"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-default">
                                        <div
                                            className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-39859f4e"
                                            data-id="39859f4e"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="elementor-element elementor-element-51e74c71 elementor-widget elementor-widget-footer-about"
                                                    data-id="51e74c71"
                                                    data-element_type="widget"
                                                    data-widget_type="footer-about.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="main-footer__about logo-retina">
                                                            <Link
                                                                to="/"
                                                                className="main-footer__logo"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    src="/logo.svg"
                                                                    width={160}
                                                                    height={48}
                                                                    alt="Creative Insight"
                                                                />
                                                            </Link>
                                                            <p className="main-footer__about__text">
                                                                Creative Insight
                                                                IT Academy
                                                                prepares bright,
                                                                motivated
                                                                individuals Who
                                                                might otherwise
                                                                not have the
                                                                opportunity To
                                                                gain the
                                                                technical and
                                                                professional
                                                                skills they need
                                                                to successfully
                                                                launch a career
                                                                in IT.
                                                            </p>
                                                            <div className="main-footer__social">
                                                                {footerSocialLinks.map(
                                                                    ({
                                                                        id,
                                                                        target,
                                                                        icon,
                                                                        link,
                                                                    }) => (
                                                                        <a
                                                                            key={
                                                                                id
                                                                            }
                                                                            href={
                                                                                link
                                                                            }
                                                                            target={
                                                                                target
                                                                            }
                                                                        >
                                                                            <i
                                                                                aria-hidden="true"
                                                                                className={
                                                                                    icon
                                                                                }
                                                                            />
                                                                        </a>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-19192280"
                                            data-id={19192280}
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="elementor-element elementor-element-3053f087 elementor-widget elementor-widget-footer-nav-menu"
                                                    data-id="3053f087"
                                                    data-element_type="widget"
                                                    data-widget_type="footer-nav-menu.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="main-footer__navmenu main-footer__widget01">
                                                            <h3 className="main-footer__title">
                                                                Quick Links{' '}
                                                            </h3>
                                                            <div className="menu-quick-links-container">
                                                                <ul
                                                                    id="menu-quick-links"
                                                                    className="ml-0"
                                                                >
                                                                    {footerQuickLinks.map(
                                                                        ({
                                                                            id,
                                                                            path,
                                                                            title,
                                                                        }) => (
                                                                            <li
                                                                                key={
                                                                                    id
                                                                                }
                                                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1014"
                                                                            >
                                                                                <Link
                                                                                    to={
                                                                                        path
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        title
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3e859eda"
                                            data-id="3e859eda"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="elementor-element elementor-element-58d53982 elementor-widget__width-initial elementor-widget elementor-widget-footer-nav-menu"
                                                    data-id="58d53982"
                                                    data-element_type="widget"
                                                    data-widget_type="footer-nav-menu.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="main-footer__navmenu main-footer__widget01">
                                                            <h3 className="main-footer__title">
                                                                Explore
                                                            </h3>
                                                            <div className="menu-explore-container">
                                                                <ul
                                                                    id="menu-explore"
                                                                    className="ml-0"
                                                                >
                                                                    {footerExploreLinks.map(
                                                                        ({
                                                                            id,
                                                                            path,
                                                                            title,
                                                                        }) => (
                                                                            <li
                                                                                key={
                                                                                    id
                                                                                }
                                                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1019"
                                                                            >
                                                                                <Link
                                                                                    to={
                                                                                        path
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        title
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-39ed72b5"
                                            data-id="39ed72b5"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="elementor-element elementor-element-7d9c2a77 elementor-widget elementor-widget-footer-contact"
                                                    data-id="7d9c2a77"
                                                    data-element_type="widget"
                                                    data-widget_type="footer-contact.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="main-footer__newsletter">
                                                            <h3 className="main-footer__title">
                                                                Contact Us{' '}
                                                            </h3>
                                                            <ul className="main-footer__info-list">
                                                                <li>
                                                                    {' '}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="icon-Location"
                                                                    />
                                                                    801, RK
                                                                    Empire, 150
                                                                    Ft. Ring
                                                                    Road, Nr.
                                                                    Mavdi
                                                                    Circle,
                                                                    Rajkot -
                                                                    360004
                                                                </li>
                                                                <li>
                                                                    {' '}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="icon-Telephone"
                                                                    />
                                                                    <a href="tel:9601917960">
                                                                        9601917960
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    {' '}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="icon-Email"
                                                                    />
                                                                    <a href="mailto:info@creativeinsightacademy.com">
                                                                        info@creativeinsightacademy.com
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Footer;
