import { Link } from 'react-router-dom';

const HeroBanner = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-009d392 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="009d392"
            data-element_type="section"
        >
            <h1 style={{display:"none"}}>Creative Insight It Academy</h1>
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-43e96ee"
                    data-id="43e96ee"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-1e2b177 elementor-widget elementor-widget-eduact-hero-area-slider"
                            data-id="1e2b177"
                            data-element_type="widget"
                            data-widget_type="eduact-hero-area-slider.default"
                        >
                            <div className="elementor-widget-container">
                                <section
                                    className="hero-banner"
                                    style={{
                                        backgroundImage:
                                            'url(../wp-content/uploads/2023/09/banner-bg-1.png)',
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="hero-banner__content">
                                                    <div
                                                        className="hero-banner__bg-shape1 wow zoomIn"
                                                        data-wow-delay="300ms"
                                                    >
                                                        <div className="hero-banner__bg-round">
                                                            <div className="hero-banner__bg-round-border" />
                                                        </div>
                                                    </div>
                                                    <h2
                                                        className="hero-banner__title wow fadeInUp"
                                                        data-wow-delay="400ms"
                                                    >
                                                        Unlock your creative
                                                        potential with Creative
                                                        Insight
                                                    </h2>
                                                    <p
                                                        className="hero-banner__text wow fadeInUp"
                                                        data-wow-delay="500ms"
                                                    >
                                                        Elevate your IT career
                                                        by tapping into your
                                                        natural abilities <br />{' '}
                                                        and developing your
                                                        inner skills.
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/banner-1-shape-1.png"
                                                            alt="alt"
                                                            title="banner-1-shape-1"
                                                        />{' '}
                                                    </p>
                                                    <div
                                                        className="hero-banner__btn wow fadeInUp"
                                                        data-wow-delay="600ms"
                                                    >
                                                        <Link
                                                            to="/courses"
                                                            className="eduact-btn eduact-btn-second"
                                                        >
                                                            <span className="eduact-btn__curve" />
                                                            Take Now
                                                            <i className="icon-arrow" />
                                                        </Link>
                                                        <Link
                                                            to="/courses"
                                                            className="eduact-btn"
                                                        >
                                                            <span className="eduact-btn__curve" />
                                                            Find The Course
                                                            <i className="icon-arrow" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div
                                                    className="hero-banner__thumb wow fadeInUp"
                                                    data-wow-delay="700ms"
                                                >
                                                    <img
                                                        decoding="async"
                                                        src="/wp-content/uploads/2023/09/banner-1-1.png"
                                                        alt="alt"
                                                        title="banner-1-1"
                                                    />{' '}
                                                    <div
                                                        className="hero-banner__cap wow slideInDown"
                                                        data-wow-delay="800ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/banner-cap.png"
                                                            alt="alt"
                                                        />
                                                    </div>
                                                    <div
                                                        className="hero-banner__star wow slideInDown"
                                                        data-wow-delay="850ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/banner-star.png"
                                                            alt="star"
                                                        />
                                                    </div>
                                                    <div
                                                        className="hero-banner__map wow slideInDown"
                                                        data-wow-delay="900ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/banner-map.png"
                                                            alt="alt"
                                                        />
                                                    </div>
                                                    <div
                                                        className="hero-banner__video wow zoomIn"
                                                        data-wow-delay="950ms"
                                                        style={{
                                                            backgroundImage:
                                                                'url(../wp-content/uploads/2023/09/banner-video.png)',
                                                        }}
                                                    >
                                                        <a
                                                            href="https://www.youtube.com/watch?v=rNmKWPA36dQ"
                                                            className="video-popup"
                                                            target="_blank" rel="noreferrer"
                                                        >
                                                            <span className="icon-play" />
                                                        </a>
                                                    </div>
                                                    <div
                                                        className="hero-banner__book wow slideInUp"
                                                        data-wow-delay="1000ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/banner-book.png"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className="hero-banner__star2 wow slideInUp"
                                                        data-wow-delay="1050ms"
                                                    >
                                                        <img
                                                            decoding="async"
                                                            src="/wp-content/uploads/2023/09/banner-star2.png"
                                                            alt="alt"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="hero-banner__border wow fadeInUp"
                                        data-wow-delay="1100ms"
                                    />
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroBanner;
