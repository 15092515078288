import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from "react-ga";

ReactGA.initialize('G-SQX1NDLBS3');
ReactGA.send({hitType:'pageView',page:window.location.pathname})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


