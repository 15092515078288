import Title from '../common/Title';
import { favoriteTopicsData } from '../../utils/data';
import { Link } from 'react-router-dom';

const CoursesSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-fe474a9 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="fe474a9"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e86a80e"
                    data-id="e86a80e"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-0fd3d33 elementor-widget elementor-widget-eduact-category-info"
                            data-id="0fd3d33"
                            data-element_type="widget"
                            data-widget_type="eduact-category-info.default"
                        >
                            <div className="elementor-widget-container">
                                {/* Category Start */}
                                <section
                                    className="category-one"
                                    style={{
                                        backgroundImage:
                                            'url(/wp-content/uploads/2023/09/category-bg-1-dark.jpg)',
                                    }}
                                >
                                    <div className="container">
                                        <div className="section-title text-center">
                                            <Title title="Courses" />
                                            <h2 className="section-title__title">
                                                Favorite Topics To Learn
                                            </h2>
                                        </div>
                                        {/* section-title */}
                                        <div className="service-card-wrapper">
                                            {favoriteTopicsData.map(
                                                ({
                                                    id,
                                                    icon,
                                                    title,
                                                    courseCount,
                                                    hoverImagePath,
                                                    imagePath,
                                                    link,
                                                }) => (
                                                    <div
                                                        key={id}
                                                        className="item service-card"
                                                    >
                                                        <div className="category-one__item">
                                                            <div
                                                                className="category-one__wrapper"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url(/wp-content/uploads/2023/09/category-shape.png)',
                                                                }}
                                                            >
                                                                <div className="category-one__thumb">
                                                                    <img
                                                                        decoding="async"
                                                                        src={
                                                                            imagePath
                                                                        }
                                                                        alt="category-normal-1"
                                                                        title="category-normal-1"
                                                                    />{' '}
                                                                </div>
                                                                <div className="category-one__content">
                                                                    <div className="category-one__icon">
                                                                        <img
                                                                            src={
                                                                                icon
                                                                            }
                                                                            alt="icon"
                                                                            style={{
                                                                                margin: 'auto',
                                                                                width: '55px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <h3 className="category-one__title">
                                                                        {title}
                                                                    </h3>
                                                                    {/*<p className="category-one__text">*/}
                                                                    {/*    {*/}
                                                                    {/*        courseCount*/}
                                                                    {/*    }{' '}*/}
                                                                    {/*    Courses*/}
                                                                    {/*</p>*/}
                                                                </div>
                                                            </div>
                                                            <div className="category-one__hover">
                                                                <div className="category-one__hover__thumb">
                                                                    <img
                                                                        decoding="async"
                                                                        src={
                                                                            hoverImagePath
                                                                        }
                                                                        alt="alt"
                                                                        title="category-hover-1"
                                                                    />{' '}
                                                                </div>
                                                                <div className="category-one__hover__content">
                                                                    <div className="category-one__hover__icon">
                                                                        <img
                                                                            src={
                                                                                icon
                                                                            }
                                                                            alt="icon"
                                                                            style={{
                                                                                margin: 'auto',
                                                                                width: '55px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <h3 className="category-one__hover__title">
                                                                        <Link
                                                                            to={
                                                                                link
                                                                            }
                                                                        >
                                                                            {
                                                                                title
                                                                            }
                                                                        </Link>
                                                                    </h3>
                                                                    {/*<p className="category-one__hover__text">*/}
                                                                    {/*    {*/}
                                                                    {/*        courseCount*/}
                                                                    {/*    }{' '}*/}
                                                                    {/*    Courses*/}
                                                                    {/*</p>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </section>
                                {/* Category End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoursesSection;
