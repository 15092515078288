const GoogleMapsSection = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-978f4f7 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="978f4f7"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-92a060f"
                    data-id="92a060f"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-1722325 elementor-widget elementor-widget-google_maps"
                            data-id={1722325}
                            data-element_type="widget"
                            data-widget_type="google_maps.default"
                        >
                            <div className="elementor-widget-container" >
                                <div className="elementor-custom-embed">
                                                 </div>
                                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14769.607985618151!2d70.7865644!3d22.2627571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cb000f203e3d%3A0x8f5bbff9a4c871b8!2sCreative%20Insight%20IT%20Academy!5e0!3m2!1sen!2sin!4v1720160024741!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GoogleMapsSection